<template>
  <div
    class="builder-content-height builder-content-bg mx-0 overflow-y-auto px-4 drag-container"
    ref="container"
  >
    <div
      v-for="(item, key) in items"
      :key="key"
      class="drag-box dynamic-component mt-2"
      draggable
      @dragstart="handleDragStart($event, key)"
      @dragenter="handleDragEnter"
      @dragover="handleDragOver"
      @dragleave="handleDragLeave"
      @drop="handleDrop($event, item, key)"
      @dragend="handleDragEnd"
    >
      <component
        :ref="`builderComponent_${key}`"
        :is="item.componentName"
        :componentKey="key"
        v-bind="{
          form: item.content.value,
          itemId: item.id
        }"
      ></component>
    </div>
  </div>
</template>

<script>
import { BUILDER_COMPONENT_EVENT_BUS } from "../services/builderComponentEventBus";

import { RepositoryFactory } from "@/services/repositories/repositoryFactory";

const experienceRepository = RepositoryFactory.get("experience");

const NoteComponent = () => import("./components/NoteComponent.vue");
const PollComponent = () => import("./components/PollComponent.vue");
const QuoteComponent = () => import("./components/QuoteComponent.vue");
const ImageComponent = () => import("./components/ImageComponent.vue");
const UserNoteComponent = () => import("./components/UserNoteComponent.vue");

export default {
  components: {
    NoteComponent,
    PollComponent,
    QuoteComponent,
    ImageComponent,
    UserNoteComponent
  },
  data: () => ({
    title: "Builder",
    items: [],
    dragSrcEl: null,
    draggedItem: null
  }),
  methods: {
    addComponentToBuilderEventBus() {
      BUILDER_COMPONENT_EVENT_BUS.$off("addComponentToBuilder");
      BUILDER_COMPONENT_EVENT_BUS.$on("addComponentToBuilder", data => {
        this.items.push({
          ...data,
          content: {
            value: {}
          }
        });
      });
    },
    deleteComponentEventBus() {
      BUILDER_COMPONENT_EVENT_BUS.$off("deleteComponent");
      BUILDER_COMPONENT_EVENT_BUS.$on("deleteComponent", ({ key, partId }) => {
        this.items.splice(key, 1);

        if (partId) {
          let experienceId = this.$route.params.id;

          experienceRepository
            .deletePart(experienceId, partId)
            .then(response => {
              this.MIXINS_SHOW_SNACKBAR(
                "success",
                response.data.responseData[0]
              );
            })
            .catch(error => {
              console.log({ error });
            });
        }
      });
    },
    getBuilderDataEventBus() {
      BUILDER_COMPONENT_EVENT_BUS.$off("getBuilderData");
      BUILDER_COMPONENT_EVENT_BUS.$on("getBuilderData", () => {
        this.setData();
      });
    },
    clearBuilderDataEventBus() {
      BUILDER_COMPONENT_EVENT_BUS.$off("clearBuilderData");
      BUILDER_COMPONENT_EVENT_BUS.$on("clearBuilderData", () => {
        this.items = [];
      });
    },
    setupEventBus() {
      this.getBuilderDataEventBus();
      this.deleteComponentEventBus();
      this.addComponentToBuilderEventBus();
      this.clearBuilderDataEventBus();
    },
    setData() {
      let isFillUpAllFields = false;

      if (this.items) {
        for (let index = 0; index < this.items.length; index++) {
          let item = this.items[index];
          let precedence = index + 1;

          let valid = this.$refs[`builderComponent_${index}`][0].validate();

          if (valid) {
            if (
              item.value === "note" ||
              item.value === "quote" ||
              item.value === "user_note"
            ) {
              let form = this.$refs[`builderComponent_${index}`][0].form;

              form.precedence = precedence;

              item.content = {
                value: form
              };
            }

            if (item.value === "poll") {
              let ref = this.$refs[`builderComponent_${index}`][0];

              let options = ref.options ? ref.options : [];

              if (options.length > 1) {
                item.content = {
                  value: {
                    question: ref.question,
                    options,
                    precedence
                  }
                };
              } else {
                isFillUpAllFields = false;

                this.MIXINS_SHOW_SNACKBAR("error", "Add at least two options.");

                break;
              }
            }

            if (item.value === "image") {
              item.content = {
                value: {
                  image: this.$refs[`builderComponent_${index}`][0].image,
                  file: this.$refs[`builderComponent_${index}`][0].file,
                  precedence
                }
              };
            }
          } else {
            isFillUpAllFields = false;

            this.MIXINS_SHOW_SNACKBAR("error", "Fill up all required fields.");

            break;
          }

          isFillUpAllFields = true;
        }

        if (isFillUpAllFields) {
          console.log(this.items, { isFillUpAllFields });
          BUILDER_COMPONENT_EVENT_BUS.$emit("setBuilderData", this.items);
        }

        return;
      }

      return;
    },
    async setUpdateData() {
      let id = this.$route.params.id;

      if (id) {
        let params = {
          full_data: true,
          sort_by_precedence: true
        };

        experienceRepository
          .showParts(id, { params })
          .then(response => {
            console.log({ response }, "setUpdateData");

            let data = response.data.responseData;

            console.log({ data });

            let items = data.map(item => {
              let id = item.id;

              if (item.type === "note") {
                return {
                  id,
                  componentName: "NoteComponent",
                  value: item.type,
                  content: {
                    value: {
                      title: item.title,
                      body: item.description
                    }
                  }
                };
              }

              if (item.type === "user_note") {
                return {
                  id,
                  componentName: "UserNoteComponent",
                  value: item.type,
                  content: {
                    value: {
                      title: item.title,
                      body: item.description
                    }
                  }
                };
              }

              if (item.type === "quote") {
                return {
                  id,
                  componentName: "QuoteComponent",
                  value: item.type,
                  content: {
                    value: {
                      reference: item.title,
                      body: item.description
                    }
                  }
                };
              }

              if (item.type === "image") {
                return {
                  id,
                  componentName: "ImageComponent",
                  value: item.type,
                  content: {
                    value: {
                      image: item.image_url
                    }
                  }
                };
              }

              if (item.type === "poll") {
                return {
                  id,
                  componentName: "PollComponent",
                  value: item.type,
                  content: {
                    value: {
                      question: item.title,
                      options: JSON.parse(item.payload)
                    }
                  }
                };
              }
            });

            this.items = items;
          })
          .catch(error => {
            console.log({ error });
          })
          .finally(() => {
            // this.items = experienceRepository.getServicesComponentDummyData();
          });
      }
    },
    handleDragStart(e, key) {
      e.srcElement.style.opacity = "0.4";

      this.dragSrcEl = e.srcElement;
      this.draggedItem = key;

      e.dataTransfer.effectAllowed = "move";
      // e.dataTransfer.setData("text/html", e.srcElement.innerHTML);
    },
    handleDragOver(e) {
      if (e.preventDefault) {
        e.preventDefault();
      }

      e.dataTransfer.dropEffect = "move";

      return false;
    },
    handleDragEnter(e) {
      e.srcElement.classList.add("over");
    },
    handleDragLeave(e) {
      e.srcElement.classList.remove("over");
    },
    handleDragEnd(e) {
      e.srcElement.style.opacity = "1";

      this.$refs.container.children.forEach(item => {
        item.classList.remove("over");
      });
    },
    handleDrop(e, item, key) {
      if (e.stopPropagation) {
        e.stopPropagation(); // stops the browser from redirecting.
      }

      let dragSrcEl = e.srcElement;

      if (dragSrcEl != this.dragSrcEl) {
        // dragSrcEl.innerHTML = this.dragSrcEl.innerHTML;
        // this.dragSrcEl.innerHTML = e.dataTransfer.getData('text/html');

        /**
         * Supply value to this.items so that it will not removed when component moves.
         */

        this.setComponentData();
        /**
         * Switching array places
         */
        this.items[key] = this.items.splice(
          this.draggedItem,
          1,
          this.items[key]
        )[0];
      }

      return false;
    },
    setComponentData() {
      this.items.forEach((item, key) => {
        if (item.value === "note" || item.value === "quote") {
          item.content = {
            value: this.$refs[`builderComponent_${key}`][0].form
          };
        }

        if (item.value === "poll") {
          let ref = this.$refs[`builderComponent_${key}`][0];

          let options = ref.options ? ref.options : [];

          if (options.length > 1) {
            item.content = {
              value: {
                question: ref.question,
                options
              }
            };
          }
        }

        if (item.value === "image") {
          item.content = {
            value: {
              image: this.$refs[`builderComponent_${key}`][0].image,
              file: this.$refs[`builderComponent_${key}`][0].file
            }
          };
        }
      });
    }
  },
  mounted() {
    this.setupEventBus();
    this.setUpdateData();
  }
};
</script>

<style lang="scss" scoped>
.builder-content {
  &-height {
    height: 600px;
  }
  &-bg {
    background: #e5e5e5;
  }
}

.drag-box {
  /**
  border: 3px solid #666;
  background-color: #ddd;
   */
  border-radius: 0.5em;
  padding: 10px;
  cursor: move;
}

.drag-box.over {
  border: 3px dotted #666;
}

[draggable] {
  user-select: none;
}

.dynamic-component {
  max-height: 400px;
  overflow-y: auto;
}
</style>
